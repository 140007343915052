<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考点管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="考点名称" class="searchboxItem ci-full">
              <span class="itemLabel">考点名称:</span>
              <el-input v-model="retrievalData.examName" type="text" size="small" placeholder="考点名称" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="to_edit">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="考点名称" align="left" show-overflow-tooltip prop="centerName" minWidth="100" />
              <el-table-column label="所属企业" align="left" show-overflow-tooltip prop="compName" minWidth="100" />
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath" minWidth="120" />
              <el-table-column label="考点地址" align="left" show-overflow-tooltip prop="address" minWidth="100" />
              <el-table-column label="操作" align="center" width="180px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_mgt(scope.row)">激活码管理</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_edit(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="delete_data(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "systemManage_examCenter",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        examName: "", // 考点名称
      },
    };
  },
  created() {

  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData
      };
      this.doFetch({
        url: "/biz/center/pa-test-center/pageList",
        params,
        pageNum,
      }, true, 10);
    },
    // 激活码管理
    to_mgt(row) {
      console.log(row);
      this.$router.push({
        path: "/practicalAssessmentSystem/systemManage/examCenter_activationCode",
        query: {
          compId: row?.compId ?? '',
        }
      })
    },
    // 编辑
    to_edit(row) {
      this.$router.push({
        path: "/practicalAssessmentSystem/systemManage/examCenter_addAndedit",
        query: {
          centerId: row?.centerId ?? ''
        }
      })
    },
    // 删除
    delete_data(row) {
      this.$post("/biz/center/pa-test-center/delete", {
        centerId: row.centerId
      }, 3000, true, 10)
        .then((res) => {
          this.$message.success('操作成功')
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>